import React, { useState } from "react";

function Donation() {
  const [quantity, setQuantity] = useState(60);
  const [characterName, setCharacterName] = useState("");

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  return (
    <div className="flex justify-center bg-black bg-opacity-50 max-w-7xl">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-2">L2Destiny</h1>
        <p className="text-center text-gray-500 mb-6">Top-up Balance And Support Server</p>

        {/* Character Name Input */}
        <label className="block text-gray-700 mb-1">Specify name of the character</label>
        <div className="flex items-center mb-4 border rounded px-3 py-2">
          <span className="bg-gray-400 border rounded-r px-4">person</span>
          <input
            type="text"
            className="flex-1 outline-none text-gray-800 bg-slate-100 pl-2"
            placeholder="Character Name"
            value={characterName}
            onChange={(e) => setCharacterName(e.target.value)}
          />
        </div>

        {/* Quantity Input */}
        <label className="block text-gray-700 mb-1">Specify quantity</label>
        <div className="flex items-center mb-4">
          <input
            type="number"
            className="border rounded-l px-3 py-2 w-full text-gray-800"
            value={quantity}
            onChange={handleQuantityChange}
          />
          <span className="bg-gray-400 border rounded-r px-4 py-2">CoL</span>
        </div>

        {/* Slider */}
        <div className="mb-4">
          <input
            type="range"
            min="5"
            max="5000"
            value={quantity}
            onChange={handleQuantityChange}
            className="w-full"
          />
          <div className="flex justify-between text-sm text-gray-500 mt-1">
            <span>5</span>
            <span>1000</span>
            <span>2100</span>
            <span>3300</span>
            <span>5000 CoL</span>
          </div>
        </div>

        {/* Currency Conversion Rates */}
        <div className="mb-4">
          <div className="flex justify-between text-sm text-gray-700">
            <span>USD</span>
            <span>6</span>
          </div>
          <div className="flex justify-between text-sm text-gray-700">
            <span>RUB</span>
            <span>585.01</span>
          </div>
          <div className="flex justify-between text-sm text-gray-700">
            <span>EUR</span>
            <span>5.53</span>
          </div>
          <div className="flex justify-between text-sm text-gray-700">
            <span>UAH</span>
            <span>247.37</span>
          </div>
        </div>

        {/* Payment Methods */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-1">Choose a payment method:</label>
          <div className="flex items-center space-x-4">
            <label className="flex items-center space-x-2">
              <input type="radio" name="payment" value="paypal" className="form-radio" />
              <img src="/path-to-your/paypal-icon.png" alt="PayPal" className="h-8" />
            </label>
            <label className="flex items-center space-x-2">
              <input type="radio" name="payment" value="stripe" className="form-radio" />
              <img src="/path-to-your/stripe-icon.png" alt="Stripe" className="h-8" />
            </label>
            <label className="flex items-center space-x-2">
              <input type="radio" name="payment" value="free-kassa" className="form-radio" />
              <img src="/path-to-your/free-kassa-icon.png" alt="Free-Kassa" className="h-8" />
            </label>
          </div>
        </div>

        {/* CAPTCHA and Submit Button */}
        <div className="mb-4">
          <div className="flex justify-center">
            <div className="bg-gray-200 border border-gray-400 rounded w-full h-12 flex items-center justify-center">
              I'm not a robot (CAPTCHA)
            </div>
          </div>
        </div>

        <button className="w-full bg-red-500 text-white py-3 rounded hover:bg-red-600 transition duration-200">
          <span className="material-icons">account_balance_wallet</span> Top-up
        </button>
      </div>
    </div>
  );
}

export default Donation;

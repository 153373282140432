import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faUser, faDownload } from '@fortawesome/free-solid-svg-icons'; 
import './Navbar.css';
import LanguageDropdown from './LanguageDropdown ';

// Import custom icons
import NewsIcon from './icons/icon.png'; 
import AboutIcon from './icons/icon2.png'; 
import downloadIcon from './icons/icon3.png'; 
import SupportIcon from './icons/icon4.png'; 

import NewsIconRed from './icons/icon_red.png'; 
import AboutIconRed from './icons/icon2_red.png'; 
import downloadIconRed from './icons/icon3_red.png'; 
import SupportIconRed from './icons/icon4_red.png'; 

import Logo from '../assets/Original.png'; // Update the path to your logo

import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered item index
  const [isScrolled, setIsScrolled] = useState(false); // Track scroll state
  // const [hoveredLogo, setHoveredLogo] = useState(false);

    // Scroll effect logic
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  const menuItems = [
    { label: "News", icon: NewsIcon, iconHover: NewsIconRed, link: "/news" },
    { label: "About", icon: AboutIcon, iconHover: AboutIconRed, link: "/serverinfo" },
    { label: "Download", icon: downloadIcon, iconHover: downloadIconRed, link: "/download" },
    { label: "Support", icon: SupportIcon, iconHover: SupportIconRed, link: "/support" }
  ];

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="px-0 -mx-6 sm:px-6 lg:px-8 sm:mx-0 lg:pl-[5%] 2xl:pl-[15%]">
      <div className="flex justify-between items-center h-16 pl-6 md:pl-25-percent">
          {/* Logo */}
          <Link to={"/"}>
          {/* <img
              src={hoveredLogo ? './Original2.png' : './Original.png'} // Change condition here
              alt="Logo"
              className="h-16 transition duration-300"
              onMouseEnter={() => setHoveredLogo(true)} // Set logo hover state to true
              onMouseLeave={() => setHoveredLogo(false)} // Reset logo hover state
            /> */}
          <img
              src={Logo} // Change condition here
              alt="Logo"
              className="h-16"
            />
          </Link>
          {/* Centered Navigation */}
          <div className="navbar-items">
            <ul className="flex justify-center space-x-4 2xl:space-x-10 font-bold">
              {menuItems.map((item, index) => (
                <li 
                  key={index} 
                  className="flex items-center"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    src={hoveredIndex === index ? item.iconHover : item.icon}
                    alt={item.label}
                    className="h-5 w-5 mr-1 transition duration-300"
                  />
                  <a 
                    href={item.link} 
                    className={`relative text-white transition duration-300 ${hoveredIndex === index ? 'text-redmoonRed glow-effect' : ''}`}
                  >
                    {item.label}
                    <span 
                      className={`absolute bottom-0 left-0 h-0.5 bg-redmoonRed w-0 transition-all duration-300 ease-out ${hoveredIndex === index ? 'w-full' : ''}`}
                    ></span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Right Buttons */}
          <div className="flex items-center space-x-1 sm:space-x-4 pr-1 2xl:pr-20">
          <Link to="/dashboard" className="flex items-center bg-black text-white px-3 py-2 border hover:border hover:border-lime-500 rounded bg-opacity-45 hover:bg-opacity-75 hover:bg-black transition duration-300">
            <span className="transition duration-300 hover:text-lime-500">
              <FontAwesomeIcon icon={faUser} />
            </span>
            {/* Login */}
          </Link>
          <Link to="/download" className="flex items-center bg-black text-white px-3 py-2 border hover:border hover:border-lime-500 rounded bg-opacity-45 hover:bg-opacity-75 hover:bg-black transition duration-300">
            <span className="transition duration-300 hover:text-lime-500">
              <FontAwesomeIcon icon={faDownload} />
            </span>
            {/* Download */}
          </Link>
            {/* Language Dropdown */}
          <LanguageDropdown />
          </div>
          {/* Mobile Menu Button */}
          <div className="md:hidden pr-3">
            <button onClick={() => setIsOpen(!isOpen)} className="text-white focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-950 p-4 bg-opacity-20">
          <ul className="flex flex-col space-y-2">
            {menuItems.map((item, index) => (
              <li 
                key={index} 
                className="flex items-center"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img src={hoveredIndex === index ? item.iconHover : item.icon} alt={item.label} className="h-5 w-5 mr-1" />
                <a href={item.link} className={`relative text-white transition duration-300 ${hoveredIndex === index ? 'text-redmoonRed glow-effect' : ''}`}>
                  {item.label}
                  <span className={`absolute bottom-0 left-0 h-0.5 bg-redmoonRed w-0 transition-all duration-300 ease-out ${hoveredIndex === index ? 'w-full' : ''}`}></span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
